.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: aliceblue;

    .contribution {
      display: flex;
      flex-direction: row;
      height: 25px;
      margin-left: 10px;

      span {
        margin: auto;
      }

      img, a {
        height: 100%;
      }
    }

    .contact {
      margin-right: 10px;
    }

    @media (min-width: 320px) { 
      * {
          font-size: 0.5em;
      }
    }

    @media (min-width: 480px) { 
      * {
          font-size: 0.75em;
      }
    }

    @media (min-width: 768px) { 
      * {
          font-size: 1em;
      }

      .contribution {
        height: 50px;
      }
    }
  }