@font-face {
  font-family: Grandstander;
  src: url("./fonts/Grandstander-Regular.otf") format("opentype");
}

body {
  margin: 0;
  font-family: Grandstander, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
