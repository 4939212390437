.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 5em;
    padding: 10px;
    background: aliceblue;

    h1 {
        font-size: 1em;
        margin-left: 25px;
    }

    @media (min-width: 320px) { 
        h1 {
            font-size: 1.2em;
        }
    }

    @media (min-width: 480px) { 
        h1 {
            font-size: 1.5em;
        }
    }

    @media (min-width: 768px) { 
        h1 {
            font-size: 2em;
        }
    }

    img {
        height: 100%;
    }
}
