$primary: #0044a8;
$secondary: #2c81ff;
$tertiary: #80b3ff;
$tertiary-75: #80b3ffbf;

.page {
    flex: 1;
    overflow: auto;
    padding: 20px;  
    background: $tertiary-75;

    .headline {
        display: flex;
        flex-direction: column;

        .isa {
            display: flex;
            flex-direction: column;
            margin: 10px 0px;
            padding:12px;
            border-radius:.5em; 
            
            span:not(:last-child) {
                margin-bottom: 10px;
            }

            &_info {
                color: #00529B;
                background-color: #BDE5F8;    
            }

            &_warning {
                color: #9F6000;
                background-color: #FEEFB3;  
            }
        }
    }

    .welcome {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 20px;

        .text {
            flex: 2;
        }
    }

    .info {
        display: flex;
        flex-direction: column;

        @media (min-width: 768px) { 
            flex-direction: row;

            .documents {
                margin-top: 0px;
                margin-left: 15px;
            }
        }

        .news-title {
            text-align: start;
            font-weight: bold;
            padding-bottom: 15px;
        }

        .documents-title {
            font-weight: bold;
            padding-bottom: 15px;
        }

        .news {
            flex: 6;
        }

        .documents {
            flex: 4;
            margin-top: 25px;
        }
    }

    .images {
        width: 85%;
        margin: 0 auto;
    }

    @media (min-width: 768px) { 
        .images {
            width: 50%;
        }
    }
    
}
