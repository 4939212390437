.accordion-summary {
    &-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: start;
    }

    &-title {
        flex: 1;
        font-weight: bold;
    }

    &-daytime {
        flex: 2;
        display: flex;
        margin-top: 10px;
    }

    @media (min-width: 560px) { 
        &-container {
            flex-direction: row;
        }

        &-daytime {
            margin-top: 0px;
        }
    }

    @media (min-width: 768px) { 
        &-container {
            flex-direction: column;
        }
        
        &-daytime {
            margin-top: 10px;
        }
    }

    @media (min-width: 920px) { 
        &-container {
            flex-direction: row;
        }

        &-daytime {
            margin-top: 0px;
        }
    }

    &-day {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &-time {
        flex: 2;
        display: flex;
        flex-direction: column;
    }
}
