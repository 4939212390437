.accordion-title-container {
    display: flex;
    width: 100%;
    text-align: start;

    div:first-child {
        flex: 2;
        font-weight: bold;
    }

    div:last-child {
        flex: 3;
        display: flex;

        span:first-child{
            flex: 1;
        }

        span:last-child{
            flex: 3;
        }
    }
}

.accordion-title {
    display: flex;
    flex-direction: column;

    span:first-child {
        margin-bottom: 10px;
    }
}

.accordion-content {
    width: 100%;
    overflow-wrap: break-word;
}